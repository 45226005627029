import React from 'react'

import HiddenLayout from '../../../../components/HiddenLayout'
import '../../../../components/fullPageCss.sass'
import queryString from 'query-string'
import storage from '../../../../lib/storage'
import { connect } from 'react-redux'
import mixpanel from 'mixpanel-browser'

class AdminNavbar extends React.Component {
  state = {}
  componentDidMount() {
    this.setState({
      token: storage.get('token'),
      id: queryString.parse(window.location.search).id,
    })
  }

  // Once user is set, tell mixpanel
  componentDidUpdate() {
    if (this.state.id && this.props.user) {
      mixpanel.track('ri:view-report', {
        report: this.state.id,
      })
    }
  }

  render() {
    const link = `https://insights.collegepulse.com/vault/survey/${this.state.id}?report&token=${this.state.token}`
    return (
      <HiddenLayout requireAuth>
        <iframe
          title="brand_infogram"
          src={link}
          className="full-page-iframe noHeader loading-gif-background"
        />
      </HiddenLayout>
    )
  }
}

export default connect(
  state => ({
    user: state.user.user,
  }),
  {}
)(AdminNavbar)
